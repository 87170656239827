#sales {
    background-image: url("background__image__sales.png");
    min-height: 600px;

    & p, h2 {
        color: white;
        text-transform: uppercase;
    }



    @media only screen and (max-width: 768px) {
        /* Стили для мобильных устройств */
        button {
            width: 100%;
        }

        img {
            display: none;
        }
    }
}