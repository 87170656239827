.navbar {
  background-color: rgba(0, 0, 0, 0.0);
  transition: 0.5s ease-in-out;
}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.9);
}

.navbar-menu {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: rgba(61, 61, 61, 0.99);
  transition: right 0.3s ease-out;
  z-index: 1000;
  overflow-y: auto;
  margin: 0;
}

.navbar-menu.active {
  right: 0;
}

.navbar-toggler {
  color: var(--main-orange-color-bg-color) !important;
  min-width: 50px !important;
  font-size: 24px !important;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
}

.overlay.active {
  display: block;
}

.navbar-item, .navbar-link {
  color: white;
  text-decoration: none;
  list-style: none;
  padding: 10px !important;
}

.navbar-item {
  margin: 0 10px;
}

.navbar-item:hover {
  cursor: pointer;
  background-color: var(--main-orange-color-bg-color);
}

#mobile__menu {
  border: none;
  padding: 0;
  border-radius: 0;
  background-color: #1e1e1eea;
  color: whitesmoke;
  left: 0;
  top: 100%;
  border-top: 7px solid var(--main-orange-color-bg-color);
}

#mobile__menu li a .active { background-color: var(--main-orange-color-bg-color) !important; }

.dropdown-item {
  color: whitesmoke !important;
  transition: .3s ease-in-out;
  padding: 10px 8px !important;
}

.dropdown-item.active {
  background-color: var(--main-orange-color-bg-color) !important;
  color: rgb(10, 10, 10) !important;
}

.dropdown-item.active::before {
  content: "❯";
  padding: 0 8px;
}

.dropdown-item:hover {
  background-color: rgba(216, 216, 216, 0.637);
  color: rgb(10, 10, 10) !important;
}

@media (max-width: 640px) {
  .navbar-item:hover {
    cursor: pointer;
    background-color: inherit !important;
  }

  .dropdown-item.active {
    cursor: pointer;
    background-color: inherit !important;
    color: whitesmoke !important;
  }
  
  .navbar-menu {
    padding: 20px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .navbar-menu .dropdown {
    margin: 0 10px !important;
  }

  #mobile__menu {
    display: block;
    position: relative;
    background-color: transparent;
    border: none;
    top: auto;
  }

  .navbar-item {
    padding: 0 !important;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .navbar-menu {
    position: static;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    right: 0;
    overflow: visible;
  }

  .navbar-item {
    padding: 0 15px;
  }

  .navbar-item.dropdown:hover .dropdown-menu {
    display: block;
  }

  .overlay {
    display: none;
  }

  .navbar-toggler {
    display: none;
  }
}