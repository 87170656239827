.footer {
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #1e1e1e;
    p, h5 {
        color: white;
    }
    .number {
        color: whitesmoke;
        font-weight: 500;

    }
    .geolocation {
        color: whitesmoke;
    }
    .geolocation:hover, .number:hover{
        color: var(--main-orange-color-bg-color);
    }
    hr {
        background-color: rgb(179, 179, 179);
        height: 2px;
    }

    a {
        color: rgb(156, 156, 156);
        text-decoration: none;  
        transition: .7s;
    }
    li {
        transition: .7s;
        &:hover {
            transform: translateX(5px);
            a {
                color: white;
            }
        }
    }    
    .social-icon {
        background-color: white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        outline: 2px solid white;
        transition: .7s;
        filter:grayscale(1);
        

        &:hover {
            transition: .7s;
            transform: scale(1.05);
            filter:grayscale(0);
        }
    }
}











