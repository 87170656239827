#pre__footer {

    

    @media only screen and (max-width: 768px) {
        /* Стили для мобильных устройств */
        button {
            width: 100%;
        }

        img {
            display: none;
        }
    }
}