

#about__company__background, #all__products__background, #contacts__background {
    background-color: whitesmoke;
    height: 200px;
    width: 100%;
    background-image: url("../../images/backgroundMMETGK.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 30%;
}



#contact__info__block {
    .left__circle__of__contact__block {
        border-radius: 50%;
        background: var(--main-orange-color-bg-color);
        height: 70px;
        width: 70px;
        flex-shrink: 0;
        outline: 5px solid whitesmoke;
    }
}

@media only screen and (max-width: 768px) {
    /* Стили для мобильных устройств */
    .right__image {
        display: none;
    }
    /* Добавьте любые другие стили, которые нужны только для мобильных устройств */
  }