.banner__container {
    /* background-image: url('bgimg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 700px;
    display: flex;
    align-items: center;
    color: white;

    & .circle__block {
        height: 300px;
        width: 300px;
        border-radius: 50%;
        margin: auto;
        position: relative;
        z-index: 1;

        &::before {
            content: "";
            top: 0;
            left: 0;
            position: absolute;
            width: inherit;
            height: inherit;
            transform: scale(1.1);
            background-color: transparent;
            border: 22px solid #1e1e1e;
            opacity: 50%;
            border-radius: 50%;
            z-index: -1;
        }
        
        &::after {
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: inherit;
            background-color: white;
            border: 22px solid #1e1e1e;
            z-index: -2;
        }

        .img__of__banner__block {
            height: 300px;
            width: 300px;
            border-radius: 0 50% 50% 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .banner__container h1 {
        text-align: center;
        font-size: 2.2rem;
    }
    .banner__container p {
        text-align: center;

    }
    .banner__container button {
        margin-top: 10px;
        width: 100%;
    }

    .banner__container .circle__block {
        display: none;
    }
  }





