:root {
  --main-orange-color-bg-color: #ffc107;
}


button, .btn {
  border-radius: 0 !important;
  padding: 10px !important;
  min-width: 200px !important;
  transition: .6s ease-in-out !important;
  font-weight: 600 !important;

  &:hover {
    transform: translateY(-10%);
  }
}
.scroll-to-top {
  max-width: 47px !important;
  min-width: 47px !important;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* padding-top: 40px; */
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}

.btn-close {
  min-width: 20px !important;
}