.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    transition: opacity 0.3s ease;
  }
  
  .overlay.hidden {
    opacity: 0;
    pointer-events: none;
  }
  