.swiper-wrapper {
    display: flex;


}

.swiper {
    background-color: whitesmoke;
    padding: 40px;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    background: #fff;
    border: 1px solid #f5f5f5;
    padding: 20px;
    border-radius: 5px;
    transition: 1s ease-in-out;
    height: auto;
    filter: grayscale(100%);
    user-select: none;
}

.swiper-slide:hover {
    transform: scale(1.05);
    cursor: pointer;
    transition: 1s;
    filter: grayscale(0);
}