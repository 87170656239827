#terms__of__cooperation p {
    height: fit-content;
    width: fit-content;
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    color: whitesmoke;
    word-wrap: break-word;
}

#terms__of__cooperation .rounded__image {
    outline: 5px solid #1e1e1e85;
    border-radius: 50%;
    background-color: var(--main-orange-color-bg-color);
    margin-right: 20px;
    flex-basis: 60px;
    flex-shrink: 0;
}

#terms__of__cooperation .left__block {
    background-color: rgb(0, 0, 0);
    height: 100%;
    padding: 40px 30px;
    justify-content: center;
    background-image: url('./test.png');
    background-size: cover;
    background-position: 70%;
}

#terms__of__cooperation .left__block .d-flex:nth-child(1) .rounded__image {
    width: 60px;
    height: 60px;
    background-image: url('./test1.png');
    background-size: 120px;
    background-position: 0% 0%;
}

#terms__of__cooperation .left__block .d-flex:nth-child(2) .rounded__image {
    width: 60px;
    height: 60px;
    background-image: url('./test1.png');
    background-size: 120px;
    background-position: 100% 100%;
}

#terms__of__cooperation .left__block .d-flex:nth-child(3) .rounded__image {
    width: 60px;
    height: 60px;
    background-image: url('./test1.png');
    background-size: 120px;
    background-position: 0% 100%;
}

#terms__of__cooperation .left__block .d-flex:nth-child(4) .rounded__image {
    width: 60px;
    height: 60px;
    background-image: url('./test1.png');
    background-size: 120px;
    background-position: 100% 0%;
}

#terms__of__cooperation .right__block {
    height: 100%;
}

#terms__of__cooperation .right__block .card__terms {
    border: none !important;
    transition: 0.7s ease-in-out;
    position: relative;
    overflow: hidden;
}

#terms__of__cooperation .right__block .card__terms img {
    object-fit: cover;
    height: 200px;
}

@media only screen and (max-width: 768px) {
    #terms__of__cooperation .left__block {
        padding: 10px 10px !important;
    }
}
